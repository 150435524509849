<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			资讯管理
			<span>&gt;</span>
			管理分类
			<button class="veadmin-iframe-button" onclick="location.reload()">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<el-row ref="elRow" style="margin:10px;">
				<el-button ref="elButton" type="primary" icon="el-icon-s-unfold" @click="initCategory()">初始化分类</el-button>
			</el-row>
			<h3 v-if="categoryList.length == 0" style="margin:0px 10px;">未初始化分类，请先执行[初始化分类]操作</h3>
			<div v-else>
				<p style="margin:6px 10px;font-weight:bold;">暂不支持修改分类</p>
				<el-table :data="categoryList" border style="margin:10px;max-width:1000px;">
					<el-table-column prop="label" label="名称" width="180"> </el-table-column>
					<el-table-column prop="weight" label="权重" width="80"> </el-table-column>
					<el-table-column prop="note" label="备注"> </el-table-column>
					<el-table-column prop="update_time" label="更新时间" width="180"> </el-table-column>
					<el-table-column prop="create_time" label="创建时间" width="180"> </el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
import { apiListCategory } from "@/api/pub/category.js";
import { apiInitCategory } from "@/api/admin/category.js";

export default {
	data() {
		return {
			categoryList: []
		};
	},
	methods: {
		// 获取分类信息
		async listCategory() {
			try {
				const response = await apiListCategory();
				//console.log(response);
				if (response.data.code == 200) {
					this.categoryList = response.data.data;
					//console.log(this.categoryList);
					if (this.categoryList.length != 0) {
						console.log(this.$refs.elButton);
						/* this.$refs.elButton.$el.className += " is-disabled";
						this.$refs.elButton.$el.disabled = "disabled"; */
						this.$refs.elRow.$el.style = "display:none;";
					}
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		},
		// 初始化分类
		async initCategory() {
			try {
				const response = await apiInitCategory(this.$store.state.token);
				console.log(response);
				if (response.data.code == 200) {
					this.$message({ message: "成功初始化", type: "success" });
					//执行成功1秒后刷新页面
					setTimeout(function() {
						location.reload();
					}, 1000);
				} else if (response.data.code == -100) {
					this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "error"
					})
						.then(() => {
							//跳转到登录页面（路由跳转则滑动验证会失效）
							//this.$router.push({ path: "/login?target=" + window.location.href });
							window.location.href = "/login?target=" + window.location.href;
						})
						.catch(() => {
							this.$message({
								type: "warning",
								message: "已取消"
							});
						});
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		this.listCategory();
	}
};
</script>

<style lang="scss" scoped></style>
